export default function Sup01({title, txt, bck}){
  return(
    <section id={`cont-${bck}`} className="sup">
      <div className="sup-box">
        <div className="sup-box-wrapper">
          <div className="sup-box-inside">
            <div className="sup-box-titles">
              <h1>{title}</h1>
            </div>

            <div className="sup-box-content">
              <p>{txt}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}