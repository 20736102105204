import Formu01 from "./formu/Formu01";
import FormulaPreview from "./FormulaPreview";

export default function Formula(){

  const txt01 = `- Damos asesoría y acompañamiento postventa a todos los requerimientos con nuestro equipo técnico.

  - Realizamos Procesos de selección y seguimiento a fabricantes de alta calidad.
  
  - Entregamos soporte documental ante autoridades regulatorias.  
  `;

  const txt02 = `- Ofrecemos al mercado moléculas innovadoras.

  - Acompañamos a nuestros clientes en el proceso de homologación y desarrollo.
  
  - Suministramos dossiers para el registro de producto ante las entidades regulatorias.  
  `;

  return(
    <section>
      <FormulaPreview/>
      <Formu01
        title={"Soporte Técnico Especializado"}
        txt={txt01}
        bck={5}
      />

    <Formu01
        title={"Desarrollos y Homologaciones"}
        txt={txt02}
        bck={6}
      />
    </section>
  )
}