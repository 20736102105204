import { Link } from "react-router-dom";

export default function AboutPreview(){
  return(
    <section className="about-preview">
      <div className="about-preview-box">
        <div className="about-preview-box-inside">
          <div className="about-preview-box-titles">
            <hr className="about-preview-box-titles-upperline"></hr>
            <h1>Nosotros</h1>
            <h2>SOMOS</h2>
          </div>

          <div className="about-preview-box-content">
            <p><span className="about-preview-box-content__smaller">un grupo farmacéutico</span> construido desde el año 2002...</p>
            <Link className="about-preview-box-content-link" to='/nosotros'>Ver más</Link>
          </div>
        </div>
      </div>
    </section>
  )
}