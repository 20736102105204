import { Link } from "react-router-dom";

export default function FormulaPreview(){
  return(
    <section className="formula-preview">
      <div className="formula-preview-box">
        <div className="formula-preview-box-inside">
          <div className="formula-preview-box-titles">
            <hr className="formula-preview-box-titles-upperline"></hr>
            <h1>Nuestra Fórmula</h1>
            <p>Una combinación ideal</p>
          </div>

          <div className="formula-preview-box-content">
            <Link className="formula-preview-box-content-link" 
            to='/formula'>Ver más</Link>
          </div>
        </div>
      </div>
    </section>
  )
}