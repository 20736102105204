export default function About(){
  return(
    <section className="about">
      <div className="about-box">
        <div className="about-box-wrapper">
          <div className="about-box-inside">
            <div className="about-box-titles">
              <hr className="about-box-titles-upperline"></hr>
              <h1>Nosotros</h1>
            </div>

            <div className="about-box-content">
              <p>Somos un grupo farmacéutico constituido desde el año 2002 con experiencia en el mercado latinoamericano; referentes en la distribución y venta de materias primas y producto terminado para la industria (Uso Humano-veterinario).</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}