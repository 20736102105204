import AboutPreview from "./AboutPreview";
import FormulaPreview from "./FormulaPreview";
import SupplyChainPreview from "./SupplyChainPreview";
import TherapeuticLinesPreview from "./TherapeuticLinesPreview";

export default function Home(){
  return(
    <div>
      <AboutPreview/>
      <TherapeuticLinesPreview/>
      <SupplyChainPreview/>
      <FormulaPreview/>
    </div>
  )
}