export default function Footer(){
  return (
    <footer className="footer">
      <div className="footer-contact-card">
        <div className="footer-contact-card-box">
          <a href='mailto:Latam@chemicalmolecules.com'>
            Latam@chemicalmolecules.com
          </a>

          <a href='mailto:ventaslatam@chemicalmolecules.com'>
            ventaslatam@chemicalmolecules.com
          </a>

          <div className="footer-logo">
            <img 
              src="/img/logo_sm.png" 
              alt="Chemical Molecules"
            />
          </div>
        </div>
      </div>
    </footer>
  )
}