import CountryBoard from "../components/CountryBoard";
import Sup01 from "./supply/Sup01";

export default function SupplyChain(){

  const txt01 = `- Desarrollamos acuerdos comerciales con más de 64 fabricantes en el mundo para el suministro de API's y Producto Terminado asegurando la calidad y el soporte documental requerido.
  
  - Nuestras Compras a Tiempo garantizan el abastecimiento oportuno en Latinoamérica.
  
  - Distribuimos productos desde nuestras bodegas ubicadas en India, Holanda, Panamá y Colombia.
  `;

  const txt02 = `- Contamos con un sistema integral operativo que nos permite en tiempo real conocer el estado de su pedido.

  - Garantizamos despachos con tiempos de tránsito cortos gracias a las negociaciones globales en fletes aéreos y marítimos.  
  `;

  const txt03 = `- Trabajamos bajo la política de “Economía de escalas”.

  - Nuestras negociaciones buscan garantizar el suministro a largo plazo.
  
  - Nos Alineamos con las políticas y estándares de producción de nuestros aliados maximizando lotes de producción para nuestros clientes.
  `;

  return(
    <section className="supply">
      <div className="supply-box">
        <div className="supply-box-wrapper">
          <div className="supply-box-inside">
            <div className="supply-box-titles">
              <hr className="supply-box-titles-upperline"></hr>
              <h1>SupplyChain</h1>
            </div>
          </div>
        </div>
      </div>
      <CountryBoard/>
      <Sup01
        title = {'Abastecimiento Oportuno'}
        txt = {txt01}
        bck = {0}
      />


      <Sup01
        title = {'Seguimiento de Pedidos'}
        txt = {txt02}
        bck = {1}
      />


      <Sup01
        title = {'Negociaciones Especiales'}
        txt = {txt03}
        bck = {2}
      />
    </section>
  )
}