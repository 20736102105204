import emailjs from 'emailjs-com';
import { useRef } from 'react';

export default function Contact() {

  const form = useRef();
  
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_gfkaobi', 'template_nj0hd8s', form.current, 'vyYDCt0GCRW3Nhsd6')
      .then((result) => {
        alert('Mensaje enviado');
      }, (error) => {
        alert(error.text);
      });

    // Clear form inputs after submitting the form
    e.target.reset();
  }

  return (
    <section className="contact">
      <div className="contact-box">
        <div className="contact-box-wrapper">
          <div className="contact-box-inside">
            <div className="contact-box-content">
              <h2>Contáctanos</h2>
              <form ref={form} className="contact-form" onSubmit={sendEmail}>
              <label for="Nombre"> Nombre </label>
              <input type="text" id="Name" name="Nombre" />

              <label for="Correo electronico"> Correo electrónico </label>
              <input type="text" id="email" name="Correo electronico" />
                
              <label for="Mensaje"> Mensaje </label>
              <input type="text" id="message" name="Mensaje" />
                
                <button type="submit">
                  Enviar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}