import { NavLink, Link } from "react-router-dom"

export default function MaterHead(){
  return (
    <header className="mast-head">
      <div className="mast-head-wrapper">
        <Link to="/">
          <div className="mast-head-logo">
            <img src="/img/logo.png" alt="Chemical Molecules"/>
          </div>
        </Link>
        
        <nav className="mast-head-navbar">
          <h1>Menú</h1>
          <ul>
            <li>
            <NavLink
              to="/nosotros"
              className={({ isActive }) => isActive ? 'mast-head-navbar-link__active' : 'mast-head-navbar-link'}>
                Nosotros
            </NavLink>
            </li>

            <li>
              <NavLink className={({ isActive }) => isActive ? 'mast-head-navbar-link__active' : 'mast-head-navbar-link'} to='/lineas-terapeuticas'>Líneas Terapéuticas</NavLink>
            </li>

            <li>
              <NavLink className={({ isActive }) => isActive ? 'mast-head-navbar-link__active' : 'mast-head-navbar-link'} to='/supply-chain'>Supply Chain</NavLink>
            </li>

            <li>
              <NavLink className={({ isActive }) => isActive ? 'mast-head-navbar-link__active' : 'mast-head-navbar-link'} to='/formula'>Nuestra Fórmula</NavLink>
            </li>

            <li>
              <NavLink className={({ isActive }) => isActive ? 'mast-head-navbar-link__active' : 'mast-head-navbar-link'} to='/contacto'>Contacto</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}