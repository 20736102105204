import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.scss'
import Contact from "./pages/Contact";
import Formula from "./pages/Formula";
import Home from "./pages/Home";
import SupplyChain from "./pages/SupplyChain";
import TherapeuticLines from "./pages/TherapeuticLines";
import MasterHead from "./components/MasterHead";
import About from "./pages/About";
import Footer from "./components/Footer";
import TherapeuticLinesDetail from "./pages/TherapeuticLinesDetail";

function App() {
  return (
    <div>
      <BrowserRouter>

      <MasterHead/>
        <Routes>
          <Route path="/" element= {<Home/>} />
          <Route path="/nosotros" element= {<About/>} />
          <Route path="/lineas-terapeuticas" element= {<TherapeuticLines/>} />
          <Route path="/lineas-terapeuticas/:id" element= {<TherapeuticLinesDetail/>} />
          <Route path="/supply-chain" element= {<SupplyChain/>} />
          <Route path="/formula" element= {<Formula/>} />
          <Route path="/contacto" element= {<Contact/>} />
        </Routes>

      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
