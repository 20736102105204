export default function Formu01({title, txt, bck}){
  return(
    <section id={`cont-${bck}`} className="formu">
      <div className="formu-box">
        <div className="formu-box-wrapper">
          <div className="formu-box-inside">
            <div className="formu-box-titles">
              <h1>{title}</h1>
            </div>

            <div className="formu-box-content">
              <p>{txt}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}