import { Link } from "react-router-dom";

export default function SupplyChainPreview(){
  return(
    <section className="supply-preview">
      <div className="supply-preview-box">
        <div className="supply-preview-box-inside">
          <div className="supply-preview-box-titles">
            <hr className="supply-preview-box-titles-upperline"></hr>
            <h1>Supply chain</h1>
          </div>

          <div className="supply-preview-box-content">
            <Link className="supply-preview-box-content-link" 
            to='/supply-chain'>Ver más</Link>
          </div>
        </div>
      </div>
    </section>
  )
}