import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import TherapeuticLines from "./TherapeuticLines";

export default function TherapeuticLinesDetail(){

  const sectionId = useParams();
  let id = "";
  let title = "";
  let body = null;
  const sec = useRef();
  useEffect(()=> {
    sec.current.scrollIntoView({behavior: 'smooth'})
  }, [sectionId.id]);

  switch (sectionId.id) {
    case 'analgesico':
      id = '01';
      title = 'Analgésico';
      body = <div className="therapy-detail-box-content">
        <h2 className="therapy-detail-list-title">
          API
        </h2>
        <ul className="therapy-detail-list">
          <li className="therapy-detail-list-item">ACETAMINOFÉN POLVO USP</li>
          <li className="therapy-detail-list-item">ACETAMINOFÉN CD 90%</li>
          <li className="therapy-detail-list-item">DEXIBUPROFENO POLVO IH</li>
          <li className="therapy-detail-list-item">CELECOXIB USP</li>
          <li className="therapy-detail-list-item">DIPIRONA SÓDICA EP COS</li>
          <li className="therapy-detail-list-item">IBUPROFENO CD 90% USP</li>
          <li className="therapy-detail-list-item">IBUPROFENO POLVO EP</li>
          <li className="therapy-detail-list-item">MELOXICAM EP / BP/ USP</li>
          <li className="therapy-detail-list-item">METOCARBAMOL USP</li>
          <li className="therapy-detail-list-item">NEPAFENACO IH</li>
          <li className="therapy-detail-list-item">PIROXICAM  USP</li>
          <li className="therapy-detail-list-item">OTROS</li>
        </ul>
        <h2 className="therapy-detail-list-title">
        DOCUMENTACIÓN
        </h2>
        <ul className="therapy-detail-list">
          <li className="therapy-detail-list-item">PAQUETE TÉCNICO</li>
        </ul>
      </div>;
      break;

      case 'antihistaminico':
        title = 'Antihistaminico';
        id = '02';
        body = body = <div className="therapy-detail-box-content">
          <h2 className="therapy-detail-list-title">
            API
          </h2>
          <ul className="therapy-detail-list">
            <li className="therapy-detail-list-item">CETIRIZINA DI HCL EP / BP</li>
            <li className="therapy-detail-list-item">CLORFENIRAMINA MALEATO USP</li>
            <li className="therapy-detail-list-item">FENILEFRINA HCL USP</li>
            <li className="therapy-detail-list-item">LORATADINA  USP</li>
            <li className="therapy-detail-list-item">DESLORATADINA USP</li>
            <li className="therapy-detail-list-item">LEVOCETIRIZINA HCL USP</li>
            <li className="therapy-detail-list-item">BUCLICINA HCL BP</li>
            <li className="therapy-detail-list-item">KETOTIFENO HIDRÓGENO FUMARATO EP</li>
            <li className="therapy-detail-list-item">FEXOFENADINA HCL USP</li>
            <li className="therapy-detail-list-item">OTROS</li>
          </ul>
          <h2 className="therapy-detail-list-title">
          DOCUMENTACIÓN
          </h2>
          <ul className="therapy-detail-list">
            <li className="therapy-detail-list-item">PAQUETE TÉCNICO</li>
          </ul>
        </div>;
        break;

        case 'antiviral-retroviral':
          title = 'Antiviral // Retroviral';
          id = '03';
          body = body = <div className="therapy-detail-box-content">
            <h2 className="therapy-detail-list-title">
              API
            </h2>
            <ul className="therapy-detail-list">
              <li className="therapy-detail-list-item">ACICLOVIR USP</li>
              <li className="therapy-detail-list-item">VALANCICLOVIR EP</li>
              <li className="therapy-detail-list-item">VALGANCICLOVIR HCL USP</li>
              <li className="therapy-detail-list-item">ABACAVIR EP/USP</li>
              <li className="therapy-detail-list-item">EMTRICITABINA IH</li>
              <li className="therapy-detail-list-item">DARUNAVIR ETANOLATO IH</li>
              <li className="therapy-detail-list-item">ATAZANAVIR USP</li>
              <li className="therapy-detail-list-item">MOLNUPIRAVIR IH</li>
              <li className="therapy-detail-list-item">RITONAVIR EP/USP</li>
              <li className="therapy-detail-list-item">LAMIVUDINA IH</li>
              <li className="therapy-detail-list-item">EFAVIRENZ USP</li>
              <li className="therapy-detail-list-item">TENOFOVIR DISOPROXIL</li>
              <li className="therapy-detail-list-item">FUMARATO IH</li>
              <li className="therapy-detail-list-item">LOPINAVIR EP/USP</li>
              <li className="therapy-detail-list-item">DOLUTEGRAVIR SODIO IH</li>
              <li className="therapy-detail-list-item">OSELTAMIVIR FOSFATO EP/USP</li>
              <li className="therapy-detail-list-item">RALTEGRAVIR POTASIO IH</li>
              <li className="therapy-detail-list-item">ZIDOVUDINA USP</li>
            </ul>
            <h2 className="therapy-detail-list-title">
            DOCUMENTACIÓN
            </h2>
            <ul className="therapy-detail-list">
              <li className="therapy-detail-list-item">PAQUETE TÉCNICO</li>
            </ul>
          </div>;
          break;

      case 'antibiotico':
        title = 'Antibiótico';
        id = '04';
        body = body = <div className="therapy-detail-box-content">
          <h2 className="therapy-detail-list-title">
            API
          </h2>
          <ul className="therapy-detail-list">
            <li className="therapy-detail-list-item">AMOXICILINA TRIHIDRATO POLVO BP</li>
            <li className="therapy-detail-list-item">CEFTRIAXONA USP</li>
            <li className="therapy-detail-list-item">AZITROMICINA DIHIDRATO USP</li>
            <li className="therapy-detail-list-item">CLARITROMICINA POLVO USP</li>
            <li className="therapy-detail-list-item">CLINDAMICINA HCL  USP</li>
            <li className="therapy-detail-list-item">TRIMETOPRIMA USP</li>
            <li className="therapy-detail-list-item">LINEZOLID IH</li>
            <li className="therapy-detail-list-item">AMIKACINA USP</li>
            <li className="therapy-detail-list-item">NEOMICINA SULFATO USP</li>
            <li className="therapy-detail-list-item">GENTAMICINA SULFATO BP/EP</li>
            <li className="therapy-detail-list-item">MOXIFLOXACINO HCL USP</li>
            <li className="therapy-detail-list-item">CIPROFLOXACINO USP</li>
            <li className="therapy-detail-list-item">SULFACETAMIDA SÓDICA USP</li>
            <li className="therapy-detail-list-item">OTROS</li>
          </ul>
          <h2 className="therapy-detail-list-title">
          DOCUMENTACIÓN
          </h2>
          <ul className="therapy-detail-list">
            <li className="therapy-detail-list-item">PAQUETE TÉCNICO</li>
          </ul>
        </div>;
        break;

      case 'gastrointestinal':
      title = 'Gastrointestinal';
      id = '05';
      body = body = <div className="therapy-detail-box-content">
        <h2 className="therapy-detail-list-title">
          API
        </h2>
        <ul className="therapy-detail-list">
          <li className="therapy-detail-list-item">HIDROTALCITA  IH</li>
          <li className="therapy-detail-list-item">SIMETICONA USP</li>
          <li className="therapy-detail-list-item">DEXLANSOPRAZOL PELLETS  (8,5% y 22,5%) IH</li>
          <li className="therapy-detail-list-item">ESOMEPRAZOL PELLETS (8,5% y 22,5%) IH</li>
          <li className="therapy-detail-list-item">OMEPRAZOL PELLETS (8.5% y 22,5%) IH</li>
          <li className="therapy-detail-list-item">LANSOPRAZOL PELLETS (8,5% y 22,5%) IH</li>
          <li className="therapy-detail-list-item">LANSOPRAZOL POLVO  USP</li>
          <li className="therapy-detail-list-item">ESOMEPRAZOL POLVO  USP</li>
          <li className="therapy-detail-list-item">RANITIDINA USP</li>
          <li className="therapy-detail-list-item">FAMOTIDINA USP</li>
          <li className="therapy-detail-list-item">HIDRÓXIDO DE MAGNESIO USP</li>
          <li className="therapy-detail-list-item">ALGINATO DE SODIO USP</li>
          <li className="therapy-detail-list-item">OTROS</li>
        </ul>
        <h2 className="therapy-detail-list-title">
        DOCUMENTACIÓN
        </h2>
        <ul className="therapy-detail-list">
          <li className="therapy-detail-list-item">PAQUETE TÉCNICO</li>
        </ul>
      </div>;
      break;

      case 'antiinflamatorio':
      title = 'Antiinflamatorio';
      id = '06';
      body = body = <div className="therapy-detail-box-content">
        <h2 className="therapy-detail-list-title">
          API
        </h2>
        <ul className="therapy-detail-list">
          <li className="therapy-detail-list-item">ACETONIDA DE TRIAMCINOLONA USP</li>
          <li className="therapy-detail-list-item">BETAMETASONA BASE USP</li>
          <li className="therapy-detail-list-item">BETAMETASONA VALERATO USP</li>
          <li className="therapy-detail-list-item">BETAMETASONA SODIO FOSFATO USP</li>
          <li className="therapy-detail-list-item">CLOBETAZOL PROPIONATO MICRON  USP</li>
          <li className="therapy-detail-list-item">DEXAMETASONA SODIO FOSFATO USP</li>
          <li className="therapy-detail-list-item">DEXAMETASONA BASE USP</li>
          <li className="therapy-detail-list-item">HIDROCORTISONA ACETATO EP</li>
          <li className="therapy-detail-list-item">MOMETASONA FUROATO USP</li>
          <li className="therapy-detail-list-item">FLUTICASONA PROPIONATO USP</li>
          <li className="therapy-detail-list-item">MONTELUKAST SÓDICO USP</li>
          <li className="therapy-detail-list-item">BUDESONIDA EP</li>
          <li className="therapy-detail-list-item">OTROS</li>
        </ul>
        <h2 className="therapy-detail-list-title">
        DOCUMENTACIÓN
        </h2>
        <ul className="therapy-detail-list">
          <li className="therapy-detail-list-item">PAQUETE TÉCNICO</li>
        </ul>
      </div>;
      break;

      case 'antihipertensivo':
      title = 'Antihipertensivo';
      id = '07';
      body = body = <div className="therapy-detail-box-content">
        <h2 className="therapy-detail-list-title">
          API
        </h2>
        <ul className="therapy-detail-list">
          <li className="therapy-detail-list-item">AMLODIPINO BESILATO BP/EP</li>
          <li className="therapy-detail-list-item">IRBESARTAN USP</li>
          <li className="therapy-detail-list-item">LOSARTÁN POTÁSICO USP</li>
          <li className="therapy-detail-list-item">VALSARTAN USP</li>
          <li className="therapy-detail-list-item">LEVOAMLODIPINO IH</li>
          <li className="therapy-detail-list-item">TELMISARTÁN USP / IH</li>
          <li className="therapy-detail-list-item">CANDESARTÁN CILEXETIL USP</li>
          <li className="therapy-detail-list-item">OLMESARTÁN MEDOXOMILO USP</li>
          <li className="therapy-detail-list-item">VERAPAMILO USP</li>
          <li className="therapy-detail-list-item">OTROS</li>
        </ul>
        <h2 className="therapy-detail-list-title">
        DOCUMENTACIÓN
        </h2>
        <ul className="therapy-detail-list">
          <li className="therapy-detail-list-item">PAQUETE TÉCNICO</li>
        </ul>
      </div>;
      break;
  
    default:
      break;
  }

  return(
    <>

      <section id={`therapy-${id}`} ref={sec} className="therapy-detail">
      <div className="therapy-detail-box">
        <div className="therapy-detail-box-wrapper">
          <div className="therapy-detail-box-inside">
            <div className="therapy-detail-box-titles">
              <h1>{title}</h1>
            </div>

            {body}
          </div>
        </div>
      </div>
    </section>

    <TherapeuticLines></TherapeuticLines>
    </>
  )
}