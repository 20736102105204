export default function CountryBoard (){
  return (
    <div className="country-board">
      <div className="country-board-wrapper">
        <div className="country-board-column">
          <h1 className="country-board-column-title">Centroamérica</h1>
          <ul className="country-board-column-list">
            <li className="country-board-column-list-item">Guatemala</li>
            <li className="country-board-column-list-item">Salvador</li>
            <li className="country-board-column-list-item">Costa Rica</li>
          </ul>
        </div>
        <div className="country-board-column">
          <h1 className="country-board-column-title">Suramérica</h1>
          <ul className="country-board-column-list">
            <li className="country-board-column-list-item">Argentina</li>
            <li className="country-board-column-list-item">Bolivia</li>
            <li className="country-board-column-list-item">Brasil</li>
            <li className="country-board-column-list-item">Cnile</li>
            <li className="country-board-column-list-item">Colombia</li>
            <li className="country-board-column-list-item">Ecuador</li>
            <li className="country-board-column-list-item">Perú</li>
            <li className="country-board-column-list-item">Uruguay</li>
          </ul>
        </div>
        <div className="country-board-column">
          <h1 className="country-board-column-title">Europa</h1>
          <ul className="country-board-column-list">
            <li className="country-board-column-list-item">Reino Unido</li>
            <li className="country-board-column-list-item">Rumanía</li>
            <li className="country-board-column-list-item">Holanda</li>
            <li className="country-board-column-list-item">España</li>
            <li className="country-board-column-list-item">Alemania</li>
            <li className="country-board-column-list-item">Eslovaquia</li>
            <li className="country-board-column-list-item">Letonia</li>
          </ul>
        </div>
        <div className="country-board-column">
          <h1 className="country-board-column-title">Asia</h1>
          <ul className="country-board-column-list">
            <li className="country-board-column-list-item">Sinagapur</li>
            <li className="country-board-column-list-item">China</li>
            <li className="country-board-column-list-item">India</li>
          </ul>
        </div>
      </div>
    </div>
  )
}