import { Link } from "react-router-dom";

export default function TherapeuticLinesPreview(){
  return(
    <section className="therapy">
      <div className="therapy-box">
        <div className="therapy-box-inside">
          <div className="therapy-box-titles">
            <hr className="therapy-box-titles-upperline"></hr>
            <h1>Líneas Terapéuticas</h1>
          </div>

          <div className="therapy-box-content">
            <Link className="therapy-box-content-link" 
            to='/lineas-terapeuticas/analgesico'>
              <div className="therapy-box-content-link__image">
                <img src="/img/therapies/01.jpg"  alt="Analgésico"/>
              </div>
              <h3 className="therapy-box-content-link__title">
                Analgésico
              </h3>
            </Link>


            <Link className="therapy-box-content-link" 
            to='/lineas-terapeuticas/antihistaminico'>
              <div className="therapy-box-content-link__image">
                <img src="/img/therapies/02.jpg"  alt="Analgésico"/>
              </div>
              <h3 className="therapy-box-content-link__title">
              Antihistamínico
              </h3>
            </Link>


            <Link className="therapy-box-content-link" 
            to='/lineas-terapeuticas/antiviral-retroviral'>
              <div className="therapy-box-content-link__image">
                <img src="/img/therapies/03.jpg"  alt="Analgésico"/>
              </div>
              <h3 className="therapy-box-content-link__title">
              Antiviral/Retroviral
              </h3>
            </Link>


            <Link className="therapy-box-content-link" 
            to='/lineas-terapeuticas/antibiotico'>
              <div className="therapy-box-content-link__image">
                <img src="/img/therapies/04.jpg"  alt="Analgésico"/>
              </div>
              <h3 className="therapy-box-content-link__title">
              Antibiótico
              </h3>
            </Link>


            <Link className="therapy-box-content-link" 
            to='/lineas-terapeuticas/gastrointestinal'>
              <div className="therapy-box-content-link__image">
                <img src="/img/therapies/05.jpg"  alt="Analgésico"/>
              </div>
              <h3 className="therapy-box-content-link__title">
              Gastrointestinal
              </h3>
            </Link>


            <Link className="therapy-box-content-link" 
            to='/lineas-terapeuticas/antiinflamatorio'>
              <div className="therapy-box-content-link__image">
                <img src="/img/therapies/06.jpg"  alt="Analgésico"/>
              </div>
              <h3 className="therapy-box-content-link__title">
              Antiinflamatorio
              </h3>
            </Link>


            <Link className="therapy-box-content-link" 
            to='/lineas-terapeuticas/antihipertensivo'>
              <div className="therapy-box-content-link__image">
                <img src="/img/therapies/07.jpg"  alt="Analgésico"/>
              </div>
              <h3 className="therapy-box-content-link__title">
              Antihipertensivo
              </h3>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}